import { getAirdropData } from 'store/airdrop/actions';
import actionTypes from 'store/airdrop/actionTypes';
import { error, request, success } from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { AirdropData, UserState } from 'types';
import { camelize } from 'utils';
import web3 from 'web3';

import { updateAirdropState } from '../reducer';

export function* getAirdropDataSaga({ type }: Pick<ReturnType<typeof getAirdropData>, 'type'>) {
  yield put(request(type));
  try {
    const userAddress: UserState['address'] = yield select(userSelector.getProp('address'));

    const { data } = yield call(baseApi.getAirdropData, { address: web3.utils.toChecksumAddress(userAddress) });

    const camelizedAirdropData = camelize(data);

    yield put(
      updateAirdropState({
        airdropData: camelizedAirdropData as AirdropData[],
      }),
    );

    yield put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_AIRDROP_DATA, getAirdropDataSaga);
}
