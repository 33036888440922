import BigNumber from 'bignumber.js';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import apiActions from 'store/api/actions';
import store from 'store/configureStore';
import { setActiveModal } from 'store/modals/reducer';
import userActionTypes from 'store/user/actionTypes';
import { approveSaga } from 'store/user/sagas/approve';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, UserState } from 'types';
import { CrowdsaleAbi } from 'types/contracts/CrowdsaleAbi';
import { estimateGasForMethod, toDecimals } from 'utils';
import { AbiItem } from 'web3-utils';

import { buyTokens } from '../actions';
import actionTypes from '../actionTypes';

import { getBuyDataSaga } from './getBuyData';
import { getClaimDataSaga } from './getClaimData';

export function* buyTokensSaga({
  type,
  payload: { web3Provider, tokenToPayAddress, amount, price },
}: ReturnType<typeof buyTokens>): Generator<unknown, void, any> {
  yield* put(apiActions.request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: crowdsaleContractAddress, abi: crowdsaleAbi } =
    contractsConfig.contracts[ContractsNames.crowdsale][chainType];

  const crowdsaleContract: CrowdsaleAbi = yield new web3Provider.eth.Contract(
    crowdsaleAbi as AbiItem[],
    crowdsaleContractAddress[network],
  );
  const tokenAmountWithDecimals = toDecimals(new BigNumber(amount).multipliedBy(new BigNumber(price)).toString(), 18);

  try {
    yield call(approveSaga, {
      type: userActionTypes.APPROVE,
      payload: {
        web3Provider,
        amount: tokenAmountWithDecimals,
        spender: crowdsaleContractAddress[network],
        tokenAddress: tokenToPayAddress,
      },
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const { estimatedGas, gasPrice } = yield* call(estimateGasForMethod, {
      web3Provider,
      contract: crowdsaleContract,
      method: 'buy',
      estimateGasArgs: { from: userAddress },
      methodArgs: [tokenToPayAddress, amount],
    });

    const { transactionHash } = yield call(crowdsaleContract.methods.buy(tokenToPayAddress, amount).send, {
      from: userAddress,
      gasPrice: Number(gasPrice),
      gas: estimatedGas,
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    yield call(getBuyDataSaga, {
      type: actionTypes.GET_BUY_DATA,
      payload: {
        web3Provider,
      },
    });

    yield call(getClaimDataSaga, {
      type: actionTypes.GET_CLAIM_DATA,
      payload: {
        web3Provider,
      },
    });

    yield* put(apiActions.success(type));
  } catch (err) {
    console.error(err);
    yield* put(apiActions.error(type, err));
    yield* put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
        repeatHandler: () =>
          store.store.dispatch({
            type,
            payload: {
              web3Provider,
              tokenToPayAddress,
              amount,
              price,
            },
          }),
      }),
    );
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.BUY_TOKENS, buyTokensSaga);
}
