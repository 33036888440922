import { select } from 'redux-saga/effects';
import { contractsConfig, ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import store from 'store/configureStore';
import { setActiveModal } from 'store/modals/reducer';
import userSelector from 'store/user/selectors';
import { call, put, takeLatest } from 'typed-redux-saga';
import { Modals, ReferralAbi, UserState } from 'types';
import { estimateGasForMethod } from 'utils';

import { claimReferralTokens } from '../actions';
import actionTypes from '../actionTypes';

import { getReferralTotalEarnedSaga } from './getReferralTotalEarned';

export function* claimReferralTokensSaga({
  type,
  payload: { airdropContractId, amount, proof, web3Provider, removeCallback },
}: ReturnType<typeof claimReferralTokens>) {
  yield put(request(type));
  const { address: userAddress, network, chainType }: UserState = yield select(userSelector.getUser);

  const { address: tokenContractAddress, abi: tokenAbi } =
    contractsConfig.contracts[ContractsNames.referral][chainType];

  try {
    if (!userAddress) return;
    const contract: ReferralAbi = yield new web3Provider.eth.Contract(tokenAbi, tokenContractAddress[network]);

    yield* put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const { estimatedGas, gasPrice } = yield* call(estimateGasForMethod, {
      web3Provider,
      contract,
      method: 'claim',
      estimateGasArgs: { from: userAddress },
      methodArgs: [airdropContractId, amount, proof],
    });

    const { transactionHash } = yield* call(contract.methods.claim(airdropContractId, amount, proof).send, {
      from: userAddress,
      gasPrice: Number(gasPrice),
      gas: estimatedGas,
    });

    yield* put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );
    removeCallback();
    yield* call(getReferralTotalEarnedSaga, {
      type: actionTypes.GET_REFERRAL_TOTAL_EARNED,
      payload: undefined,
    });
    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield* put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
        repeatHandler: () =>
          store.store.dispatch({
            type,
            payload: {
              web3Provider,
              airdropContractId,
              amount,
              proof,
            },
          }),
      }),
    );
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM_REFERRAL_TOKENS, claimReferralTokensSaga);
}
